import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Recaptcha from 'react-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { navigate } from "gatsby"

const ContactForm = ({ pageContext, thankyou, subscription }) => {
  const graphqlResult = useStaticQuery(graphql`
    query contactFormFile {
      contactFormLabels: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "contact-form-labels"}}) {
        contactformlabels {
          nameSurnameContactFormLabels
          emailContactFormLabels
          emailErrorContactFormLabels
          emailEmptyContactFormLabels
          telErrorContactFormLabels
          messageContactFormLabels
          consentContactFormLabels
          buttonContactFormLabels
          fileUploadContactFormLabels
          fileUploadErrorContactFormLabels
          fileUploadInfoContactFormLabels
        }
      }
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const labels = graphqlResult.contactFormLabels.contactformlabels
  const nameLabel = labels.nameSurnameContactFormLabels
  const fileLabel = labels.fileUploadContactFormLabels
  const fileError = labels.fileUploadErrorContactFormLabels
  const fileInfo = labels.fileUploadInfoContactFormLabels
  const emailLabel = labels.emailContactFormLabels
  const emailError = labels.emailErrorContactFormLabels
  const emailEmpty = labels.emailEmptyContactFormLabels
  const telLabel = labels.telErrorContactFormLabels
  const messageLabel = labels.messageContactFormLabels
  const consentText = labels.consentContactFormLabels
  const submitLabel = labels.buttonContactFormLabels


  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo
  // const handlers = {
  //   'pl-PL-brand':    'phpHandlers/contact-form-brand.php',
  //   'pl-PL-sport':    'phpHandlers/contact-form-sport.php',
  //   'pl-PL-business': 'phpHandlers/contact-form-business.php',
  //   'en-EN-brand':    'phpHandlers/contact-form-EN-brand.php',
  //   'en-EN-sport':    'phpHandlers/contact-form-EN-sport.php',
  //   'en-EN-business': 'phpHandlers/contact-form-EN-business.php',
  //   'de-DE-brand':    'phpHandlers/contact-form-DE-brand.php',
  //   'de-DE-sport':    'phpHandlers/contact-form-DE-sport.php',
  //   'de-DE-business': 'phpHandlers/contact-form-DE-business.php'
  // }
  const handlers = {
    'pl-PL-file': 'phpHandlers/file-input-PL.php',
    'en-EN-file': 'phpHandlers/file-input-EN.php',
    'de-DE-file': 'phpHandlers/file-input-DE.php'
  }
  // const phpHandler = handlers[`${currentLang}-${pageContext.siteSlug}`] 
  const phpHandler = handlers[`${currentLang}-file`]   
  const thankYouSlug = `${pageContext.sitePath}${thankyou}`
  // const thankYouSlug = `${pageContext.realSlugs[pageContext.siteSlug]}${thankyou}`
  const redirectBack = pageContext.pagePath


  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [message, setMessage] = useState('')
  const [consent, setConsent] = useState(false)
  const [subscriptionCB, setSubscriptionCB] = useState(false)
  const [files, setFiles] = useState('')
  const formRef = useRef(null)
  const [resp, setResp] = useState(null)

  // const disabled = consent ? false : true

  // console.log(subscription)

  const handleTextChange = (e, fn) => fn(e.target.value)

  const handleFileInput = (e, fn) => {
    if(e.target && e.target.files[0]) {

      if(e.target.files[0].size < 5000000 ) {
  
        fn(e.target.files[0])  

      }
      else {
        setErrors(prevState => ({ ...prevState, file: fileError }))
      }
      
    }

    // IF YOU NEED MANAGE MULTIPLE FILE UPLOAD START HERE:
    // if(e.target.files.length > 0) {
    //   // console.log(e.target.files)
    //   const temp = []
    //   Array.from(e.target.files).forEach(it => {
    //     temp.push(it)
    //   })
    //   fn((prevState) => [...prevState, ...temp])
    // }
  }

  const handleConsentChange = e => setConsent(e.target.checked)

  const errorsInit = { email: '', consent: '', file: '', server: '' }
  const [errors, setErrors] = useState(errorsInit)


  const recaptchaInstance = useRef(null)
  // const [disableSubmit,setDisableSubmit] = useState(true)
  const [responseSsr, setResponseSsr] = useState('')

  const executeCaptcha = function () {
    // recaptchaInstance.current.reset()    ?????????
    recaptchaInstance.current.execute()
    // console.log('EXECUTE')
  }

  useEffect(() => {
    recaptchaInstance.current !== null && recaptchaInstance.current.reset()
    // console.log('recaptcha reset by useEffect')
  }, [])


  const validateForm = e => {
    e.preventDefault()

    let currentErrors = errorsInit
    let formIsValid = true;
    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if(!email) {
      formIsValid = false
      currentErrors.email = emailEmpty
    }
    else if (!email.match(mailformat)) {
      formIsValid = false
      currentErrors.email = emailError
    }
    else if (email.match(mailformat)) {
      // document.getElementById('formTrue').click()

      executeCaptcha()

    }

    setErrors(currentErrors)
  }


  const verifyCallback = function (response) {
    // console.log(response)
    setResponseSsr(response)
    // document.getElementById('formTrue').click()
    // console.log('VERIFY CALLBACK DONE')
  }

  // AXIOS REQUEST HANDLE
  useEffect(() => {
    if(typeof responseSsr === 'string' && responseSsr !== '') {
      if(formRef.current !== null) {

        // console.log('___VERIFY CALLBACK 2')
        // console.log(responseSsr)
        
        const data = new FormData(formRef.current)
        data.append('responsessr', responseSsr)
        data.append('autoRespond', 'default')
        data.append('redirect', redirectBack)
        
        // console.log('___VERIFY CALLBACK 3')
  
        axios({
          method: "post",
          url: `/${phpHandler}`,
          data: data,
          headers: { 
            "Content-Type": "multipart/form-data" 
            // "Content-Type": "text/plain" 
          },
        })
        .then(function (response) {
          //handle success
          // console.log('SUCCESS RESPONSE: ', response)
  
          if(response.statusText === "OK") {
            setResp({
              thankYouSlug: thankYouSlug,
              redirectBack: redirectBack
            })
          }
          else {
            let currentErrors = errorsInit
            currentErrors.server = 'Server error'
            setErrors(currentErrors)
          }  
  
        })
        .catch(function (response) {
          //handle error
          // console.log('ERROR RESPONSE: ', response)

          // SPECIAL CASE FOR FRESHMAIL ERROR
          if( response.response.data.includes('success') ) {
            setResp({
              thankYouSlug: thankYouSlug,
              redirectBack: redirectBack
            })
          }
          else {            
            let currentErrors = errorsInit
            currentErrors.server = 'Server error'
            setErrors(currentErrors)
          }

        })
  
      }
    }
  },[responseSsr])


  useEffect(() => {
    if(resp !== null) {
      if(typeof resp.thankYouSlug !== 'undefined' && typeof resp.redirectBack !== 'undefined') {
  
        // console.log(resp)
        
        navigate(`${resp.thankYouSlug}?${resp.redirectBack}`)  
      }
    }
  },[resp])

  useEffect(() => {
    // setConsent(false)
    setConsent(consent)
  }, [])


  return (
    <>
      <form 
        noValidate 

        onSubmit={validateForm} 

        id="visibleForm" 
        name="visible_form" 
        encType="multipart/form-data"
        method="POST"

        ref={(form)=> formRef.current = form} 
      >
        <input
          className="name"
          type="text"
          name="name"
          placeholder={nameLabel}
          value={name}
          onChange={e => handleTextChange(e, setName)}
        ></input>
        <span className="error"></span>
        <input
          style={errors.email !== '' ? {border:'1px solid #F33'} : {}}
          className="email"
          type="email"
          name="email"
          placeholder={emailLabel}
          required
          value={email}
          onChange={e => handleTextChange(e, setEmail)}
        ></input>
        <span className="error">{errors.email}</span>
        <input
          className="tel"
          type="tel"
          name="phone"
          placeholder={telLabel}
          value={tel}
          onChange={e => handleTextChange(e, setTel)}
        ></input>
        <span className="error"></span>
        <textarea
          className="message"
          name="message"
          rows="12"
          placeholder={messageLabel}
          value={message}
          onChange={e => handleTextChange(e, setMessage)}
        ></textarea>

        {files === '' ?
          <>
            <label 
              htmlFor="inputfile" 
              className={`button backLink cta`}
              tabIndex="0"
              role="button"
              style={{
                padding: '0',
                marginTop: '20px'
              }}
            >
              {fileLabel}
            </label>              

          </>
        :
          <span className={`fileDelete`} style={{minHeight:'62px'}}>
            <span className="consent">{files.name}</span>
            <button
              className={`backLink cta`}                
              onClick={() => setFiles('')}
              onKeyDown={() => setFiles('')}
              style={{display:'flex',justifyContent:'center',alignItems:'center'}}
            >
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          </span>
        }

        <input type="hidden" name="MAX_FILE_SIZE" value="5000000"
          style={{
            visibility: 'hidden',
            maxWidth: '0',
            maxHeight: '0',
            height: '0',
            overflow: 'hidden',
            lineHeight: '0',
            display: 'none'
          }}
        ></input>
        <input
          id="inputfile"
          className="file"
          type="file"
          name="file"
          accept=".doc,.docx,.pdf,.jpg,.png"
          // multiple={true}    //
          placeholder={fileLabel}
          onChange={e => handleFileInput(e, setFiles)}
          style={{
            visibility: 'hidden',
            maxWidth: '0',
            maxHeight: '0',
            height: '0',
            overflow: 'hidden',
            lineHeight: '0',
            display: 'none'
          }}
        ></input>
        <span className={`error ${errors.file ? 'active' : ''}`}>{errors.file}</span>

        <div 
          className="consent" 
          style={{marginBottom:`${subscription.freshmailSubscriptionCheckbox ? '10px' : '40px'}`}}  
        >
          <label className="consent">
            <input
              className="consent-check"
              type="checkbox"
              id="consent"
              value={consent}
              onChange={handleConsentChange}
            />
          </label>
          <span dangerouslySetInnerHTML={{ __html: consentText }}></span>
          <span className="error">{errors.consent}</span>
        </div>



        {/* subscription checkbox */}
        {subscription.freshmailSubscriptionCheckbox && 
        <div 
          className="consent subscription" 
          style={{marginBottom:'20px'}} 
        >
          <label className="consent subscription">
            <input
              className="consent-check subscription"
              type="checkbox"
              id="consent"
              value={subscriptionCB ? subscription.freshmailSubscriptionsListId : false}
              name="subscription"
              onChange={() => setSubscriptionCB(subscriptionCB ? false : true)}
            />
          </label>
          <span dangerouslySetInnerHTML={{ __html: subscription.checkboxDescriptionForUsers }}></span>
        </div>
        }



        {/* <button 
          className={`${consent ? 'active' : 'unactive'}`} 
          type="submit" 
          disabled={consent ? false : true}
        >
          {submitLabel}
        </button> */}

        {/* RECAPTCHA BUTTON */}
        <button 
          className={`cta ${consent ? 'active' : 'unactive'}`} 
          type="submit" 
          disabled={(consent && formRef.current !== null) ? false : true}
          // onClick={executeCaptcha}
        >
          {submitLabel}
        </button>

        {typeof window !== 'undefined' && <Recaptcha 
          elementID="formRecaptcha"
          // ref={e => recaptchaInstance.current = e}
          ref={recaptchaInstance}
          // sitekey="6LdE2hobAAAAAEzTOyYz7z82leax5-86isS-wHBz"  // OLD PRODUCTION KEY
          sitekey="6LcuBd4hAAAAAJWEMmt-ZqwQjaL32qfXWLFo1NTR"  // PRODUCTION KEY
          // sitekey="6Le3aTkhAAAAAHYVHa0HncO7a5Bz_S3tbpmc5zDX"  // DEV KEY
          verifyCallback={verifyCallback}
          render="explicit"
          size="invisible"

          // onChange={useCallback(() => setDisableSubmit(false))}
          // onChange={() => setDisableSubmit(false)}
          onChange={() => console.log('')}

          onloadCallback={() => console.log("recaptcha loaded")}
          // onloadCallback={callback}
        />}

      </form>

      {/* <form 
        noValidate

        // onSubmit={e => 
        //   e.preventDefault,
        //   console.log('HIDDEN FORM SUBMIT')
        // }

        id="contact_form" 
        method="post" 
        name="contact_form" 
        action={`/${phpHandler}`}
      >
        <input
          type="hidden"
          name="name"
          value={name}
        >          
        </input>
        <input
          type="hidden"
          name="email"
          required
          value={email}
        >          
        </input>
        <input
          type="hidden"
          name="phone"
          value={tel}
        >          
        </input>
        <textarea
          style={{display:'none'}}
          type="hidden"
          name="message"
          value={message}
        >          
        </textarea>

        <input type="hidden" name="thankyou" value={thankYouSlug}></input>
        <input type="hidden" name="redirect" value={redirectBack}></input>
        <input type="hidden" name="responsessr" value={responseSsr}></input>

        <button 
          id="formTrue"
          style={{display:'none'}}
          type="submit" 
          disabled={consent ? false : true}
        >          
        </button>

      </form>       */}
    </>
  )
}
export default ContactForm